import myRequest from '@/services/myRequest';

// 查询所有平台
export async function queryPlatformList(params) {
  return myRequest('get', '/platform/queryPlatformList', params);
}

// 添加平台账号
export async function addAccountInfo(params) {
  return myRequest('post', '/platform/addAccountInfo', params, { requestType: 'json' });
}

// 查询平台账号
export async function queryAccountInfo() {
  return myRequest('get', '/platform/queryAccountInfo');
}

// 更新平台账号状态
export async function updateAccountInfoById(params) {
  return myRequest('post', '/platform/updateAccountInfoById', params, {
    requestType: 'json',
  });
}

// 进入账号中心日志
export async function enterUserCenterLog(params) {
  return myRequest('get', '/adminLog/enterUserCenter', params);
}

// 批量删稿日志
export async function removeArticleLog(params) {
  return myRequest('post', '/adminLog/removeArticleLog', params, {
    requestType: 'json',
  });
}

export async function getUserConfig(params) {
  return myRequest('get', '/user/getUserConfig', params);
}
