import myRequest from '@/services/myRequest';

export async function getReadInfoList(params) {
  return myRequest('get', '/dataStats/itemFollowStats', params);
}

export async function addReadInfo(params) {
  return myRequest('post', '/dataStats/itemFollowPatch', params);
}

export async function getAllReporter(params) {
  return myRequest('get', '/dataStats/getAllReporter', params);
}

export async function getAllEditor(params) {
  return myRequest('get', '/dataStats/getAllEditor', params);
}